import styled from "styled-components";
import logo from "assets/images/logo.png";
import logo2 from "assets/images/icon-logo-mobile.png";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { useEffect, useState } from "react";
import { useWidthScreen } from "helpers/hooks/useWidthScreen";
import Menu from "./Menu";
import MenuButton from "./MenuButton";
import { Link } from "react-router-dom";
import WalletButton from "./WalletButton";
import PickLang from "components/core/PickLang";
import Notify from "./Notify";

const Header = () => {
  const [show, setShow] = useState<boolean>(false);
  const [showAcc, setShowAcc] = useState<boolean>(false);
  const { width } = useWidthScreen();

  useEffect(() => {
    window.onscroll = function () {
      // We add pageYOffset for compatibility with IE.
      if (window.scrollY >= 10 || window.pageYOffset >= 10) {
        document.getElementById("header")?.classList.add("has-color");
      } else {
        document.getElementById("header")?.classList.remove("has-color");
      }
    };
  }, []);

  return (
    <Wrap className="" id="header">
      <div className="container wrap-header">
        <Link to="/" className="logo cursor-pt">
          <img src={logo2} alt="logo" />
        </Link>
        <div className="menu ">
          {width >= 1200 && <Menu onClose={() => {}} />}
          <WalletButton />
          {width >= 1200 && <PickLang />}
          {/* {width >= 1200 && <Notify />} */}
          {width < 1200 && (
            <MenuButton
              onClick={() => {
                setShow(!show);
              }}
              show={show}
            />
          )}
        </div>
      </div>
      {show && <div className="bg-shadow"></div>}
      <div
        className={`menu-mobile ${show && "menu-mobile-active"} ${
          showAcc && "menu-mobile-acc"
        }`}
        onClick={() => {
          setShow(false);
        }}
      >
        <Menu
          onClose={() => {
            setShow(false);
          }}
        />
      </div>
    </Wrap>
  );
};

export default Header;

const Wrap = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
  transition: 0.3s ease-out;
  &.has-color {
    background: #1a1a1a;
  }
  .wrap-header {
    display: flex;
    align-items: center;
    /* background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%); */
    /* width: 100%; */
    height: 80px;
    z-index: 1;
    .menu {
      display: flex;
      align-items: center;
      flex: 1;
    }
    .logo {
      width: 40.5px;
      height: 40px;
      margin-right: auto;
    }
  }
  .menu-mobile {
    position: fixed;
    width: 100%;
    height: 100%;
    transition: 0.5s ease-out;
    top: -100%;
    left: 0;
    z-index: 0;
  }
  .menu-mobile-acc {
    z-index: 1;
  }
  .menu-mobile-active {
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
  }
  .bg-shadow {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 0;
    background-color: #000000d0;
  }
  ${breakpointsMedias.min768} {
    .wrap-header {
      /* height: 80px; */
      .logo {
        width: 160px;
        height: auto;
        content: url(${logo});
      }
    }
  }
  ${breakpointsMedias.min1200} {
    justify-content: center;
    position: absolute;
    &.has-color {
      background: unset;
    }
    .wrap-header {
      height: fit-content;
      /* padding-top: 28px; */
      padding-top: 20px;
      padding-bottom: 16px;
      .menu {
        flex: 1;
        display: flex;
        align-items: center;
      }
      .logo {
        /* width: 305px; */
        width: 205px;
        transform: translateX(-15px);
      }
    }
    .menu-mobile {
      display: none;
    }
    .bg-shadow {
      display: none;
    }
  }
`;
