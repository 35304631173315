import { gql } from "@apollo/client";

export const GET_ROOM = gql`
  query rl_get_available_rooms($page: Int, $pageSize: Int) {
    rl_get_available_rooms(page: $page, pageSize: $pageSize) {
      total
      data {
        id
        size
        amount
        created_at
        members {
          amount
          address
        }
        settle_result {
          amount
          address
        }
      }
    }
  }
`;

export const GET_ROOM_DETAIL = gql`
  query rl_get_room_by_id($id: String!) {
    rl_get_room_by_id(id: $id) {
      id
      size
      amount
      created_at
      members {
        amount
        address
      }
      settle_result {
        amount
        address
      }
      settle_txid
    }
  }
`;

export const GET_ROOM_TEMP = gql`
  query rl_get_room_id {
    rl_get_room_id
  }
`;

export const GET_USER_HISTORY = gql`
  query rl_get_user_room_history(
    $address: String!
    $page: Int
    $pageSize: Int
  ) {
    rl_get_user_room_history(
      address: $address
      page: $page
      pageSize: $pageSize
    ) {
      total
      data {
        id
        size
        amount
        created_at
        members {
          amount
          address
        }
        settle_result {
          amount
          address
        }
      }
    }
  }
`;

export const CREATE_ROOM = gql`
  mutation rl_create_room(
    $id: String!
    $size: Int!
    $amount: Float!
    $address: String!
    $signature: String!
    $deposit_amount: Float!
  ) {
    rl_create_room(
      id: $id
      size: $size
      amount: $amount
      address: $address
      signature: $signature
      deposit_amount: $deposit_amount
    ) {
      id
    }
  }
`;

export const DEPOSIT_ROOM = gql`
  mutation rl_deposit(
    $id: String!
    $address: String!
    $signature: String!
    $deposit_amount: Float!
  ) {
    rl_deposit(
      id: $id
      address: $address
      signature: $signature
      deposit_amount: $deposit_amount
    )
  }
`;

export const MINT_WTRX = gql`
  mutation rls_user_demo_mint_wtrx($address: String!) {
    rls_user_demo_mint_wtrx(address: $address)
  }
`;
export const MUTATION_DELEGATE_PERMISSIONS = gql`
  mutation Rls_user_grant_delegate_permission(
    $paymentFrequency: EPaymentFrequency!
    $maintainUndelegateEnergy: Int!
    $maintainUndelegateBandwidth: Int!
    $minEnergyPrice: Int!
    $minBandwidthPrice: Int!
    $paymentAddress: String!
    $minDelegateAmount: Int!
    $reclaimOnlyTronsave: Boolean
    $profitShare: Int
  ) {
    rls_user_grant_delegate_permission(
      payment_frequency: $paymentFrequency
      maintain_undelegate_energy: $maintainUndelegateEnergy
      maintain_undelegate_bandwidth: $maintainUndelegateBandwidth
      min_energy_price: $minEnergyPrice
      min_bandwidth_price: $minBandwidthPrice
      payment_address: $paymentAddress
      min_delegate_amount: $minDelegateAmount
      reclaim_only_tronsave: $reclaimOnlyTronsave
      profit_share: $profitShare
    )
  }
`;
export const MUTATION_LOGIN = gql`
  mutation setLogin(
    $address: String!
    $timestamp: Float!
    $signature: String!
    $duration_sec: Int
  ) {
    rls_login(
      address: $address
      timestamp: $timestamp
      signature: $signature
      duration_sec: $duration_sec
    )
  }
`;

export const GET_RECEIPTS = gql`
  query Rls_permit_user(
    $address: String!
    $page: Int
    $pageSize: Int
    $timeFilterBy: ETimeFilter
    $from: Float
    $to: Float
  ) {
    rls_permit_user(address: $address) {
      address
      receipt_history(
        page: $page
        pageSize: $pageSize
        timeFilterBy: $timeFilterBy
        from: $from
        to: $to
      ) {
        data {
          delegator
          receiver
          resource_amount
          paid_amount
          paid_fee
          paid_txid
          order_id
          status
          paid_at
          delegate_txid
          delegate_at
          reclaim_txid
          will_reclaim_at
          create_at
          update_at
        }
        total
      }
    }
  }
`;

export const GET_DELEGATES = gql`
  query Rls_permit_user(
    $address: String!
    $page: Int
    $pageSize: Int
    $timeFilterBy: ETimeFilter
    $from: Float
    $to: Float
  ) {
    rls_permit_user(address: $address) {
      address
      delegate_history(
        page: $page
        pageSize: $pageSize
        timeFilterBy: $timeFilterBy
        from: $from
        to: $to
      ) {
        data {
          delegator
          receiver
          resource_type
          amount
          order_id
          status
          delegate_at
          delegate_txid
          create_at
          update_at
          # reclaim_at
          # reclaim_txid
          # will_reclaim_at
        }
        total
      }
    }
  }
`;

export const GET_STATISTIC = gql`
  query rls_market_statistic {
    rls_market_statistic {
      resource_info {
        total_available_energy
        total_limit_energy
      }
      resource_recover_info {
        pool_delegate_release_details {
          date_time
          amount
        }
        pool_delegate_release_chunks {
          date_time
          amount
        }
      }
      total_energy_recover
      total_pool
      apy
    }
  }
`;
export const GET_STATISTICS_APY = gql`
  query rls_market_statistic {
    rls_market_statistic {
      apy
    }
  }
`;

export const GET_ACCOUNT = gql`
  query Rls_permit_user($address: String!) {
    rls_permit_user(address: $address) {
      min_delegate_amount
      min_energy_price
      payment_address
      payment_frequency
      maintain_undelegate_energy
      permit_operations
      profit_share
      reclaim_only_tronsave
      setting {
        is_enable_auto_stake
        is_enable_auto_vote
        is_enable_auto_withdraw
        min_stake_amount_in_sun
        remaining_amount_in_sun
        max_delegate_duration_sec
        is_accept_extend
      }
    }
  }
`;
export const SET_SELL_RESOURCE_MANUAL = gql`
  mutation setSellResourceManual(
    $resourceType: ResourceType!
    $orderID: String
    $txID: String
  ) {
    rls_user_sell_resource_manual(
      resource_type: $resourceType
      order_id: $orderID
      tx_id: $txID
    )
  }
`;

export const GET_ORDERS = gql`
  query rls_active_order(
    $page: Int
    $pageSize: Int
    $is_fulfilled: Boolean
    $sortFilterBy: [SOrder]
  ) {
    rls_active_order(
      page: $page
      pageSize: $pageSize
      is_fulfilled: $is_fulfilled
      sortFilterBy: $sortFilterBy
    ) {
      total
      data {
        _id
        target
        requester
        resource_type
        resource_amount
        transaction_info {
          delegate_txid
          address
          delegate_amount
          payment
          is_extended
        }
        price
        fulfilled
        create_at
        is_lock
        payout
        allow_partial_full
        duration_sec
        apy
        update_at
        remain_amount
        type
        min_energy_delegate_amount
      }
    }
  }
`;

export const GET_BOT = gql`
  query rls_system_setting {
    rls_system_setting {
      max_profit_share
      address_recharge_order
      dynamic_buy_energy_price
      dynamic_sell_energy_price
      dynamic_lowest_energy_price
    }
  }
`;

export const MUTATION_ORDER = gql`
  mutation rls_user_create_order_buy_resource(
    $resource_type: ResourceType!
    $unit_price: Float!
    $allow_partial_fill: Boolean!
    $tx_id: String!
    $target_address: String
    $duration_millisec: Float
    $signed_tx: JSON
    $isAirdrop: Boolean
    $min_energy_delegate_required_amount: Float
    $only_create_when_fulfilled: Boolean
  ) {
    rls_user_create_order_buy_resource(
      resource_type: $resource_type
      unit_price: $unit_price
      allow_partial_fill: $allow_partial_fill
      tx_id: $tx_id
      target_address: $target_address
      duration_millisec: $duration_millisec
      signed_tx: $signed_tx
      is_airdrop: $isAirdrop
      min_energy_delegate_required_amount: $min_energy_delegate_required_amount
      only_create_when_fulfilled: $only_create_when_fulfilled
    )
  }
`;

export const GET_MY_ORDERS = gql`
  query rls_user_order_history(
    $page: Int
    $pageSize: Int
    $address: String!
    $sortFilterBy: SOrder
    $isAirdrop: Boolean
  ) {
    rls_user_order_history(
      page: $page
      pageSize: $pageSize
      address: $address
      sortFilterBy: $sortFilterBy
      is_airdrop: $isAirdrop
    ) {
      total
      data {
        _id
        target
        requester
        resource_type
        resource_amount
        transaction_info {
          delegate_txid
          address
          delegate_amount
          payment
          is_extended
          expired_at
        }
        price
        fulfilled
        create_at
        is_lock
        payout
        duration_sec
        cancel_at
        type
      }
    }
  }
`;

export const GET_ORDER_ID = gql`
  query rls_active_order_by_id($_id: String!) {
    rls_active_order_by_id(_id: $_id) {
      _id
      target
      resource_type
      resource_amount
      transaction_info {
        delegate_txid
        address
        delegate_amount
        payment
      }
      price
      fulfilled
      create_at
      is_lock
      payout
      allow_partial_full
    }
  }
`;
export const GET_USER_DELEGATE_LOCK = gql`
  query getUserDelegateLock($address: String!) {
    rls_user_delegate_by_other_lock_gets(address: $address)
  }
`;

export const CANCEL_ORDER = gql`
  mutation rls_user_cancel_order_buy_resource($_id: String!) {
    rls_user_cancel_order_buy_resource(_id: $_id)
  }
`;

export const MUTATION_SELL_RESOURCE = gql`
  mutation Rls_user_sell_resource_manual(
    $resourceType: ResourceType!
    $orderId: String
    $txId: String
    $signedTx: JSON
    $paymentAddress: String
  ) {
    rls_user_sell_resource_manual(
      resource_type: $resourceType
      order_id: $orderId
      tx_id: $txId
      signed_tx: $signedTx
      payment_address: $paymentAddress
    )
  }
`;

export const GET_REF_INFOR = gql`
  query rls_user_ref_info($address: String!) {
    rls_user_ref_info(address: $address) {
      _id
      user_id
      sponsor
      user_ref_code
      pending_ref_code
      create_at
    }
  }
`;

export const SET_PENDING_REF = gql`
  mutation rls_user_set_pending_ref($ref_code: String!, $address: String!) {
    rls_user_set_pending_ref(ref_code: $ref_code, address: $address) {
      sponsor
      pending_ref
      alert
    }
  }
`;

export const GENERATE_CODE = gql`
  mutation rls_user_set_ref_code($ref_code: String!) {
    rls_user_set_ref_code(ref_code: $ref_code)
  }
`;

export const SET_ALL_REF = gql`
  mutation rls_user_set_referral($ref_code: String!, $sponsor_code: String) {
    rls_user_set_referral(ref_code: $ref_code, sponsor_code: $sponsor_code)
  }
`;

export const GET_REF_ACTIVE_INFOR = gql`
  query rls_user_ref_info($address: String!) {
    rls_user_ref_info(address: $address) {
      total_ref
      total_ref_bonus
      remain_ref_bonus
    }
  }
`;

export const GET_REWARD_HISTORY = gql`
  query rls_user_commission_history(
    $address: String!
    $page: Int
    $pageSize: Int
  ) {
    rls_user_commission_history(
      address: $address
      page: $page
      pageSize: $pageSize
    ) {
      total
      data {
        amount
        txid
        create_at
      }
    }
  }
`;
export const MUTATION_UPDATE_FEARTURE_TRONSAVE = gql`
  mutation Mutation(
    $reclaim_only_tronsave: Boolean
    $enable_auto_stake: Boolean
    $enable_auto_vote: Boolean
    $enable_auto_withdraw: Boolean
    $min_stake_amount_in_sun: Float
    $remaining_amount_in_sun: Float
    $off_pool: Boolean
    $max_delegate_duration_sec: Float
    $is_accept_extend: Boolean
  ) {
    rls_user_update_feature(
      reclaim_only_tronsave: $reclaim_only_tronsave
      enable_auto_stake: $enable_auto_stake
      enable_auto_vote: $enable_auto_vote
      enable_auto_withdraw: $enable_auto_withdraw
      min_stake_amount_in_sun: $min_stake_amount_in_sun
      remaining_amount_in_sun: $remaining_amount_in_sun
      off_pool: $off_pool
      max_delegate_duration_sec: $max_delegate_duration_sec
      is_accept_extend: $is_accept_extend
    )
  }
`;
//airdrop
export const GET_STATISTIC_AIRDROP = gql`
  query Resource_info($isAirdrop: Boolean) {
    rls_market_statistic {
      resource_info {
        total_airdrop_available_energy
        total_airdrop_limit_energy
      }
      resource_recover_info(is_airdrop: $isAirdrop) {
        pool_delegate_release_chunks {
          date_time
          amount
        }
      }
      total_energy_recover(is_airdrop: $isAirdrop)
    }
  }
`;
export const GET_ORDERS_AIRDROP = gql`
  query rls_active_order(
    $page: Int
    $pageSize: Int
    $is_fulfilled: Boolean
    $sortFilterBy: [SOrder]
    $isAirdrop: Boolean
  ) {
    rls_active_order(
      page: $page
      pageSize: $pageSize
      is_fulfilled: $is_fulfilled
      sortFilterBy: $sortFilterBy
      is_airdrop: $isAirdrop
    ) {
      total
      data {
        _id
        target
        requester
        resource_type
        resource_amount
        transaction_info {
          delegate_txid
          address
          delegate_amount
          payment
        }
        price
        fulfilled
        create_at
        is_lock
        payout
        allow_partial_full
        duration_sec
        apy
        update_at
        remain_amount
      }
    }
  }
`;

export const SUB_ACTIVE_ORDER = gql`
  subscription Subscription {
    rls_active_order_sub {
      data {
        _id
        target
        requester
        resource_type
        resource_amount
        allow_partial_full
        duration_sec
        price
        payout
        is_lock
        fulfilled
        create_at
        apy
        update_at
        remain_amount
        min_energy_delegate_amount
      }
      action
    }
  }
`;
export const QUERY_MULTI_SIGN_PERMISSION = gql`
  query rls_get_multisign_permission_id(
    $ownerAddress: String
    $permitUserAddress: String
  ) {
    rls_get_multisign_permission_id(
      owner_address: $ownerAddress
      permit_user_address: $permitUserAddress
    ) {
      id
      operations
    }
  }
`;
export const QUERY_ORDER_BOOKS = gql`
  query Rls_order_book(
    $address: String
    $min_delegate_amount: Float
    $duration_sec: Float
  ) {
    rls_order_book(
      address: $address
      min_delegate_amount: $min_delegate_amount
      duration_sec: $duration_sec
    ) {
      min
      value
    }
  }
`;
export const EDIT_TARGET = gql`
  mutation rls_user_update_order(
    $order_id: String
    $new_target_address: String
  ) {
    rls_user_update_order(
      order_id: $order_id
      new_target_address: $new_target_address
    ) {
      target
    }
  }
`;

export const GET_EXTENDABLE = gql`
  query rls_check_extendable($order_id: String) {
    rls_check_extendable(order_id: $order_id) {
      address
      is_extendable
      extend_price
      reclaim_time
      amount
      extend_min_time_to
      id
      available_extend_at
      reason
    }
  }
`;

export const GET_EXTENDABLE_PAID = gql`
  query rls_estimate_extend_payout(
    $order_id: String!
    $matched_ids: [String]!
    $extend_to: Float!
  ) {
    rls_estimate_extend_payout(
      order_id: $order_id
      matched_ids: $matched_ids
      extend_to: $extend_to
    )
  }
`;

export const MUTATION_EXTEND = gql`
  mutation rls_user_extend_request(
    $order_id: String!
    $matched_ids: [String]!
    $extend_to: Float!
    $signed_tx: JSON!
  ) {
    rls_user_extend_request(
      order_id: $order_id
      matched_ids: $matched_ids
      extend_to: $extend_to
      signed_tx: $signed_tx
    )
  }
`;
export const GET_ACTIVE_RECEIVERS = gql`
  query Rls_get_active_receivers(
    $address: String!
    $resourceType: ResourceType!
  ) {
    rls_get_active_receivers(address: $address, resource_type: $resourceType) {
      address
      expired_at
    }
  }
`;
export const GET_EXTENDS_DELEGATE = gql`
  query Rls_get_extendable_delegates(
    $requester: String!
    $resourceType: ResourceType!
    $receiver: String!
  ) {
    rls_get_extendable_delegates(
      requester: $requester
      resource_type: $resourceType
      receiver: $receiver
    ) {
      max_extend_amount
      total_available_extra_buy_amount
      min_extend_required
      data {
        address
        amount
        available_energy
        available_extend_at
        extend_min_time_to
        extend_price
        is_extendable
        reason
        reclaim_time
        extend_min_time_to
        available_extend_at
      }
    }
  }
`;
export const GET_ESTIMATE_EXTEND = gql`
  query getEstimateExtend(
    $requester: String!
    $receiver: String!
    $resourceType: ResourceType!
    $extendData: [ExtendAndBuyInput]!
    $overBuyAmount: Float
    $overBuyTo: Float
    $overBuyMaxPrice: Float
  ) {
    rls_estimate_extend_and_buy_payout(
      requester: $requester
      receiver: $receiver
      resource_type: $resourceType
      extend_data: $extendData
      over_buy_amount: $overBuyAmount
      over_buy_to: $overBuyTo
      over_buy_max_price: $overBuyMaxPrice
    ) {
      total_estimate_trx
      total_extend_amount
      total_extra_buy_amount
      estimate_data {
        address
        extend_price
        extend_amount
        estimate_extend_trx
        estimate_extra_buy_trx
        extra_buy_amount
        extra_buy_price
        extra_buy_failure_reasons
        extend_failure_reasons
        cal_extend_duration
        cal_extra_buy_duration
        max_extendable_duration_sec
      }
      est_over_buy_trx
      est_over_buy_price
      est_over_buy_amount
      total_current_delegated_amount
    }
  }
`;
export const MUTATION_EXTEND_AND_BUY = gql`
  mutation setExtendAndBuy(
    $requester: String!
    $receiver: String!
    $resourceType: ResourceType!
    $extendData: [ExtendAndBuyInput]!
    $signedTx: JSON
    $apiKey: String
    $overBuyAmount: Float
    $overBuyTo: Float
    $overBuyMaxPrice: Float
  ) {
    rls_extend_and_buy_request(
      requester: $requester
      receiver: $receiver
      resource_type: $resourceType
      extend_data: $extendData
      signed_tx: $signedTx
      api_key: $apiKey
      over_buy_amount: $overBuyAmount
      over_buy_to: $overBuyTo
      over_buy_max_price: $overBuyMaxPrice
    )
  }
`;

export const GET_API_KEY = gql`
  query rls_user_internal_account {
    rls_user_internal_account {
      internal_address
      internal_balance
      api_key
    }
  }
`;

export const REVOKE_KEY = gql`
  mutation rls_user_revoke_api_key {
    rls_user_revoke_api_key
  }
`;
export const GET_EXTENDABLE_DELEGATE_QUICK = gql`
  query Rls_get_extendable_delegates(
    $requester: String!
    $receiver: String!
    $resourceType: ResourceType!
    $suggestData: ExtendAndBuySuggestInput
  ) {
    rls_get_extendable_delegates(
      requester: $requester
      receiver: $receiver
      resource_type: $resourceType
      suggest_data: $suggestData
    ) {
      data {
        address
        amount
        is_extendable
        extend_price
        reclaim_time
        extend_min_time_to
        available_extend_at
        reason
        available_energy
        suggest_data {
          min_buy_more_price
          max_buy_more_price
          suggest_extend
          suggest_buy_more_amount
          suggest_extend_to
        }
      }
      min_extend_required
      total_available_extra_buy_amount
      max_extend_amount
      over_buy_amount
      over_buy_energy_price
      over_buy_amount_available
      over_buy_amount_max_available
    }
  }
`;

export const SET_TELE_NOTIFY = gql`
  mutation rls_user_set_contact(
    $type: EContactType!
    $is_active: Boolean
    $value: String!
    $alert_threshold: Float
  ) {
    rls_user_set_contact(
      type: $type
      is_active: $is_active
      value: $value
      alert_threshold: $alert_threshold
    )
  }
`;

export const GET_CONTACT_TELE = gql`
  query rls_user_contact_info($address: String!, $contact_type: EContactType!) {
    rls_user_contact_info(address: $address, contact_type: $contact_type) {
      address
      value
      is_active
      is_verify
      alert_threshold
    }
  }
`;

export const VERIFY_TELE_NOTIFY = gql`
  mutation rls_user_verify_contact(
    $code_verify: String!
    $contact_type: EContactType!
  ) {
    rls_user_verify_contact(
      code_verify: $code_verify
      contact_type: $contact_type
    )
  }
`;

export const GET_LOCK_INFO = gql`
  query getLockInfo($address: String!) {
    rls_get_full_delegator_by_receiver_address(address: $address) {
      delegate_value
      delegate_address
      lock_to
    }
  }
`;

export const GET_PROFIT_STATIS = gql`
  query rls_permit_user($address: String!) {
    rls_permit_user(address: $address) {
      statistic {
        count_matched_order
        total_energy_delegated
        total_trx_earn
        avg_price
      }
    }
  }
`;

export const GET_PROFIT_DATA = gql`
  query rls_permit_user_statistic_chart_data(
    $address: String!
    $timeRangeType: optionsTimeRange
  ) {
    rls_permit_user_statistic_chart_data(
      address: $address
      timeRangeType: $timeRangeType
    ) {
      sync_date
      count_matched_order
      total_energy_delegated
      total_trx_earn
      avg_price
    }
  }
`;

export const GET_PRICE_TABLE = gql`
  query rls_get_energy_price_table($address: String!) {
    rls_get_energy_price_table(address: $address) {
      durations
      amounts
      prices
    }
  }
`;
//exclusive
export const MUTATION_EXCLUSIVE_USER_APY = gql`
  query rls_exclusive_user_apy($price: Float) {
    rls_exclusive_user_apy(price: $price)
  }
`;
export const MUTATION_USER_REGISTER_EXCLUSIVE = gql`
  mutation rls_user_register_exclusive($min_price: Float) {
    rls_user_register_exclusive(min_price: $min_price)
  }
`;
export const MUTATION_USER_UPDATE_EXCLUSIVE = gql`
  mutation rls_user_update_exclusive_setting($min_price: Float) {
    rls_user_update_exclusive_setting(min_price: $min_price)
  }
`;
export const MUTATION_USER_EXIT_EXCLUSIVE = gql`
  mutation rls_user_exit_exclusive {
    rls_user_exit_exclusive
  }
`;
