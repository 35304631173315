import LoadingScreen from "components/core/LoadingScreen";
import { GlobalStyle } from "configs/constants/globalStyles";
import Apollo from "contexts/Apollo";
import TronContainer from "contexts/TronContext";
import TronContextsWrap from "contexts/TronContexts";
import { Suspense, lazy } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import bgShadow from "assets/images/bg-shadow.png";
import "react-toastify/dist/ReactToastify.css";
import "languages/index";
import styled from "styled-components";
import Header from "components/ui/header/Header";
import Footer from "components/ui/footer/Footer";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import RefContainer from "contexts/RefContext";
import ResourceContextWrap from "contexts/ResourceContext";
import TeleApp from "pages/TeleApp/TeleApp";
import "@tronweb3/tronwallet-adapter-react-ui/style.css";
import Multibuy from "pages/Multibuy/Multibuy";
import TrySaveWalet from "components/core/TrySaveWalet";
import Popup from "components/ui/Popup/Popup";
import MaintainPage from "./pages/maintain/MaintainPage";

const DemoNft = lazy(() => import("pages/DemoNft/DemoNft"));
const Demo = lazy(() => import("pages/Demo/Demo"));
const RoomDetail = lazy(() => import("pages/Demo/RoomDetail"));
const UserHistory = lazy(() => import("pages/Demo/UserHistory"));
const Home = lazy(() => import("pages/Home/Home"));
const Energy = lazy(() => import("pages/energy/Energy"));
const Airdrop = lazy(() => import("pages/airdrop/Airdrop"));

const App = () => {
  return (
    <>
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <TronContextsWrap>
          <Apollo>
            <GlobalStyle />
            <TrySaveWalet />
            <ResourceContextWrap>
              <BrowserRouter>
                <Routes>
                  <Route path="/buy-form" element={<TeleApp />} />
                  <Route
                    path="*"
                    element={
                      <Suspense fallback={<LoadingScreen />}>
                        {/* {!show ? (
                    <LoadingScreen />
                  ) : ( */}
                        <Wrap id="wrap-body">
                          <div className="bg-shadow">
                            <div>
                              <img src={bgShadow} />
                            </div>
                          </div>
                          <Header />

                          <RefContainer>
                            <Routes>
                              {/* <Route
                                path="/demo"
                                element={
                                  <TronContainer>
                                    <DemoNft />
                                  </TronContainer>
                                }
                              />
                              <Route path="/market" element={<Energy />} />
                              <Route path="/airdrop" element={<Airdrop />} />
                              <Route path="/demo2" element={<Demo />} />
                              <Route
                                path="demo2/:id"
                                element={<RoomDetail />}
                              />
                              <Route
                                path="user/:idUser"
                                element={<UserHistory />}
                              />
                              <Route path="/multibuy" element={<Multibuy />} />
                              <Route
                                path="/maintain"
                                element={<MaintainPage />}
                              /> */}
                              <Route path="*" element={<Energy />} />
                            </Routes>
                            <Footer />
                          </RefContainer>
                          <Popup />
                        </Wrap>
                        {/* )} */}
                      </Suspense>
                    }
                  />
                </Routes>
              </BrowserRouter>
            </ResourceContextWrap>
          </Apollo>
        </TronContextsWrap>
      </ErrorBoundary>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default App;
const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background: #161c17;
  > .bg-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 94%;
    height: fit-content;
    z-index: 0;
    display: flex;
    transform: translate(-47%, -43%);
    > div {
      padding-top: 88.8%;
      width: 100%;
      /* background-image: url(${bgShadow});
      background-size: 100% auto;
      background-position: top center;
      background-repeat: no-repeat; */
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 84.5%;
        top: 0;
        left: 0;
        border-radius: 1808.466px;
        background: radial-gradient(
          86.4% 86.4% at 50% 50%,
          rgba(107, 204, 133, 0.2) 0%,
          rgba(193, 255, 210, 0) 53.65%
        );
        filter: blur(19px);
      }
      &::after {
        content: "";
        position: absolute;
        width: 53.22%;
        height: 84.5%;
        top: 8.5%;
        left: 18.4%;
        border-radius: 1356.349px;
        background: radial-gradient(
          86.4% 86.4% at 50% 50%,
          rgba(107, 204, 133, 0.2) 0%,
          rgba(193, 255, 210, 0) 53.65%
        );
        filter: blur(19px);
      }
      > img {
        position: absolute;
        width: 57.25%;
        height: auto;
        bottom: 0;
        left: 20%;
      }
    }
  }
  ${breakpointsMedias.max1199} {
    > .bg-shadow {
      width: 161.7%;
    }
  }
  ${breakpointsMedias.max767} {
    /* min-height: 100vh;
    height: 100vh;
    scroll-snap-type: y mandatory;
    overflow: auto; */
  }
`;
